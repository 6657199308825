//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { HierarchyUtil } from "./s25.event.type.hierarchy.util";

@TypeManagerDecorator("s25-ng-event-type-expanded-content")
@Component({
    selector: "s25-ng-event-type-expanded-content",
    template: `
        <div *ngIf="this.init" id="{{ this.id }}">
            <div *ngIf="this.loading">Loading....</div>
            <div *ngIf="this.itemTypeId === 1">
                <p *ngIf="this.cabinetsList === 'undefined' || this.cabinetsList === ''">
                    This event type does not belong to a cabinet type yet.
                </p>
                <p *ngIf="this.foldersList === 'undefined' || this.foldersList === ''">
                    This event type does not belong to a folder type yet.
                </p>

                <div *ngIf="this.cabinetsList && !this.loading" class="c-margin-top--half">
                    <div *ngIf="this.type === 'C'">
                        {{ this.cabinetName }}
                        <ul *ngIf="this.cabinetsList !== '' || this.cabinetsList.indexOf('undefined') === -1">
                            <li *ngFor="let c of this.cabinetsList">
                                <span *ngIf="c"
                                    ><a class="c-textButton" href="{{ cabinetDetailUrl }}{{ c.cabinet_id }}">{{
                                        c.cabinet_name
                                    }}</a></span
                                >
                                <span *ngIf="!c">This event type does not belong to a cabinet type yet.</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div *ngIf="this.foldersList && !this.loading" class="c-margin-top--half">
                    <div *ngIf="this.type === 'F'">
                        {{ this.folderName }}
                        <ul *ngIf="this.foldersList !== '' || this.foldersList.indexOf('undefined') === -1">
                            <li *ngFor="let f of this.foldersList">
                                <span *ngIf="f"
                                    ><a class="c-textButton" href="{{ folderDetailtUrl }}{{ f.event_id }}">{{
                                        f.event_name
                                    }}</a>
                                    ({{ f.cabinet_name }})</span
                                >
                                <span *ngIf="!f">This event type does not belong to a folder type yet.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div *ngIf="this.itemTypeId === 2 && this.parentCabinet">
                {{ this.parentCabinet.event_type_name }}
                <ul *ngIf="this.cabinetsList !== ''">
                    <li *ngFor="let c of this.cabinetsList">
                        <span [ngClass]="{ ngBold: this.parentCabinet.event_name === c.event_name }"
                            ><a class="c-textButton" href="{{ cabinetDetailUrl }}{{ c.event_id }}">{{
                                c.event_name
                            }}</a></span
                        >
                    </li>
                </ul>
            </div>

            <div *ngIf="this.itemTypeId === 3 && this.parentCabinet">
                {{ this.parentCabinet.event_type_name }}
                <ul *ngIf="this.foldersList !== ''">
                    <li *ngFor="let c of this.foldersList">
                        <span [ngClass]="{ ngBold: this.parentCabinet.event_name === c.event_name }"
                            ><a class="c-textButton" href="{{ folderDetailtUrl }}{{ c.event_id }}">{{
                                c.event_name
                            }}</a>
                            ({{ c.cabinet_name }})
                        </span>
                    </li>
                </ul>
            </div>

            <div *ngIf="this.itemTypeId === 1 && this.type === 'req'">
                <ul *ngIf="this.req && this.req.length > 0">
                    <li *ngFor="let r of this.req">
                        {{ r.requirement }}
                    </li>
                </ul>
                <ul *ngIf="!this.req">
                    <li>None</li>
                </ul>
            </div>

            <div *ngIf="this.itemTypeId === 1 && this.type === 'custAtt'">
                <ul *ngIf="this.custAtt && this.custAtt.length > 0">
                    <li *ngFor="let c of this.custAtt">
                        {{ c.attribute_name }}
                    </li>
                </ul>
                <ul *ngIf="!this.custAtt">
                    <li>None</li>
                </ul>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ExpandedContentComponent implements OnInit {
    static count: number = 0;
    @Input() type: any = "C";
    @Input() typeId: any;
    @Input() listData: any;
    @Input() itemTypeId?: number = 1;
    @Input() itemId?: number = 0;

    init = false;
    loading = false;
    cabinetsList: any;
    foldersList: any;
    listToFolderTree: any;
    items: any[];
    path: any[];

    cabinetName: any;
    folderName: any;
    id: any;
    parentCabinet: any;
    req: any;
    custAtt: any;

    cabinetDetailUrl = HierarchyUtil.getCompUrl("hierarchy") + "/2/edit/C/";
    folderDetailtUrl = HierarchyUtil.getCompUrl("hierarchy") + "/3/edit/F/";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.id = "expand-" + S25ExpandedContentComponent.count++;
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.init = true;
        this.loading = true;
        this.cd.detectChanges();
        if (this.itemTypeId === 1) {
            this.toggleExpand().then((resp) => {
                this.loading = false;
                this.cd.detectChanges();
            });
        } else if (this.type === "C" && this.itemTypeId === 2) {
            this.parentCabinet = S25Util.array.getByProp(this.listData, "event_id", this.typeId);
            if (this.parentCabinet) {
                this.cabinetsList = this.listData.filter(
                    (i: any) => i.event_type_id === this.parentCabinet.event_type_id,
                );
            }
            this.loading = false;
            this.cd.detectChanges();
        } else if (this.type === "F" && this.itemTypeId === 3) {
            this.parentCabinet = S25Util.array.getByProp(this.listData, "event_id", this.typeId);
            if (this.parentCabinet) {
                this.foldersList = this.listData.filter(
                    (i: any) => i.event_type_id === this.parentCabinet.event_type_id,
                );
            }
            this.loading = false;
            this.cd.detectChanges();
        }
    }

    toggleExpand() {
        this.loading = true;
        this.cd.detectChanges();
        let endAfter = new Date().getFullYear() - 10 + "1231";
        let getCabinetId: any;
        let getFolderId: any;

        if (this.type !== "req" && this.type !== "custAtt") {
            let listData = S25Util.deepCopy(this.listData);
            let path = HierarchyUtil.findPath(
                HierarchyUtil.listToTree(listData, { idKey: "type_id", parentKey: "parent_id", childrenKey: "folder" }),
                this.typeId,
                "",
                "type_id",
            );
            if (path.length > 0) {
                getCabinetId = path[0].type_id;
                this.cabinetName = path[0].type_name;
                if (path[1]) {
                    getFolderId = path[1].type_id;
                    this.folderName = path[1].type_name;
                }
            }
        }

        let promiseArr: any = [];
        if (this.type === "C") {
            // doing this because the js expaned contents calling need done promise first, other error
            promiseArr.push(EventService.getNodeTypeByEventTypeId("C", getCabinetId));
        } else if (this.type === "req" || this.type === "custAtt") {
            promiseArr.push(EventService.getEventTypeById(this.typeId));
        } else {
            promiseArr.push(EventService.getNodeTypeByEventTypeId("F", getFolderId, endAfter));
        }
        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                if (this.type === "C") {
                    S25Util.array.isArray(resp[0]) ? (this.cabinetsList = resp[0]) : (this.cabinetsList = resp);
                } else if (this.type === "req" || this.type === "custAtt") {
                    console.log({ resp: resp[0] });
                    if (resp[0] && resp[0].custom_attributes) {
                        this.custAtt = S25Util.array.forceArray(resp[0].custom_attributes);
                        console.log({ cust: this.custAtt });
                    }
                    if (resp[0] && resp[0].requirements) {
                        this.req = S25Util.array.forceArray(resp[0].requirements);
                        console.log({ req: this.req });
                    }
                } else {
                    S25Util.array.isArray(resp[0]) ? (this.foldersList = resp[0]) : (this.foldersList = resp);
                }
            });
        }
    } //toggleExpand
}
