//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
    ViewRef,
    ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import { EventService } from "../../services/event.service";
import { LockService } from "../../services/lock.service";
import { DropdownApi } from "../s25-dropdown/dropdown.api";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { HierarchyUtil } from "./s25.event.type.hierarchy.util";
import { EventTypeFormI } from "../../pojo/HierarchyI";
import { jSith } from "../../util/jquery-replacement";
import { Api } from "../../api/api";
import { HierarchyApi } from "./s25.event.type.hierarchy.component";
import { MasterDefinitionService } from "../../services/master.definitions/master.definition.service";
import { UserprefService } from "../../services/userpref.service";
import { S25NotificationPolicyComponent } from "../../modules/s25-notification-policy/s25.notification.policy.component";
import { MultiselectResultsApi } from "../s25-multiselect/s25.multiselect.results.component";
import { ReportService, Report } from "../../services/report.service";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { AlertService } from "../../services/alert.service";
export class EventTypeApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return Api.callApiFn(relativeElem, "s25-ng-event-type", null, null, (comp: S25EventTypeComponent) => {
            comp && comp.ngOnInit();
        });
    }
}
@TypeManagerDecorator("s25-ng-event-type")
@Component({
    selector: "s25-ng-event-type",
    template: `
        <h4 class="c-margin-top--half" *ngIf="this.type === 'E'">{{ this.isEdit ? "Edit" : "New" }} Event Type</h4>

        <div *ngIf="this.init && this.eventType && this.reportsInit && this.type != 'view'">
            <div *ngIf="this.folders && !this.isEdit && !this.isCopy">
                <div>
                    <label class="c-margin-right--single c-margin-top--half cabinet-type">
                        <span class="ngBlock c-margin-bottom--quarter" id="cabinet"
                            ><span class="ngBold">Cabinet Type </span><span class="c-required">- Required</span></span
                        >
                        <s25-generic-dropdown
                            [items]="this.cabinets"
                            [placeholder]="'Select a Cabinet Type'"
                            [searchEnabled]="true"
                            [(chosen)]="this.chosenCabinet"
                            (chosenChange)="onChangeCabinet($event)"
                        ></s25-generic-dropdown>
                    </label>
                    <ng-container>
                        <label id="folder" class="folder-type">
                            <span class="ngBlock c-margin-bottom--quarter c-margin-top--half ngBold">Folder Type </span>
                            <s25-ng-hierarch-dropdown
                                [items]="this.folders"
                                [searchEnabled]="true"
                                [placeholder]="'Select a Folder Type'"
                                [(chosen)]="this.chosenFolder"
                                (chosenChange)="onChangeFolder($event)"
                            ></s25-ng-hierarch-dropdown>
                        </label>
                        <div
                            *ngIf="this.folderTypeMsg !== '' && this.folders && this.folders.length"
                            class="c-margin-top--half  ngBold cn-alert cn-alert--warning"
                        >
                            {{ this.folderTypeMsg }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <label class="c-margin-right--double event-type c-margin-top--half">
                <span class="ngBlock c-margin-bottom--quarter"
                    ><span class="ngBold">Event Type Name </span><span class="c-required">- Required</span></span
                >
                <input
                    type="text"
                    class="c-input cn-form__control"
                    name="type_name"
                    id="type_name"
                    [value]="this.eventType.type_name"
                    (change)="onChangeName($event)"
                    (focusout)="setFolderTypeMsg()"
                    maxlength="80"
                />
            </label>
            <label class="vcal">
                <span class="ngBold ngBlock c-margin-bottom--quarter c-margin-top--half">VCal Processing</span>
                <select *ngIf="vCalOptions" class="ngListPageDropdown" name="vCal" (change)="onChangeVCal($event)">
                    <option *ngFor="let v of vCalOptions" [value]="v.val" [selected]="v.val === this.vCal">
                        {{ v.txt }}
                    </option>
                </select>
            </label>
            <label class="hierarchy--section-label  c-margin-bottom--none">Reports</label>

            <div class="hierarchy--flex-wrapper">
                <span class="c-margin-right--double c-margin-bottom--half">
                    <label for="confir">Confirmation Notice</label>
                    <s25-generic-dropdown
                        [staticItems]="this.staticItems"
                        [items]="this.reportsDropdownList"
                        [placeholder]="'Select a Report'"
                        [searchEnabled]="true"
                        [(chosen)]="this.chosenConfirmation"
                        (chosenChange)="onChangeReportDropdown($event)"
                    ></s25-generic-dropdown>
                </span>
                <span class="c-margin-bottom--half">
                    <label for="invoice">Invoice</label>
                    <s25-generic-dropdown
                        [staticItems]="this.staticItems"
                        [items]="this.reportsDropdownList"
                        [placeholder]="'Select a Report'"
                        [searchEnabled]="true"
                        [(chosen)]="this.chosenInvoice"
                        (chosenChange)="onChangeReportDropdown($event)"
                    ></s25-generic-dropdown>
                </span>
            </div>

            <s25-ng-multiselect-search-criteria
                [selectedItems]="this.selectedReports"
                [modelBean]="this.modelBeanReports"
                honorMatching="true"
                type="eventTypeReports"
                (change)="onChangeReportsValue()"
            ></s25-ng-multiselect-search-criteria>

            <div
                *ngIf="
                    this.eventContractBase.length > 0 ||
                    this.organizationContractBase.length > 0 ||
                    this.reservationContractBase.length > 0
                "
            >
                <svg class="c-svgIcon ngCpointer" role="img">
                    <title>Info</title>
                    <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"></use>
                </svg>
            </div>

            <div *ngIf="this.eventContractBase.length > 0" class="c-margin-left--single">
                If Event Contract is selected, then
                <a class="c-textButton" (click)="this.docContractModal.open()">these document management reports</a>
                will be added to this event type.
                <s25-ng-modal #docContractModal [title]="'Event-Based Documents'" [size]="'xl'">
                    <ng-template #s25ModalBody>
                        <div *ngFor="let r of eventContractList">
                            {{ r.report_name }}
                        </div>
                    </ng-template>
                </s25-ng-modal>
            </div>
            <div *ngIf="this.organizationContractBase.length > 0" class="c-margin-left--single">
                If Event Organization Shell is selected, then
                <a class="c-textButton" (click)="this.docContractModal.open()">these document management reports</a>
                will be added to this event type.
                <s25-ng-modal #docContractModal [title]="'Organization-Based Documents'" [size]="'xl'">
                    <ng-template #s25ModalBody>
                        <div *ngFor="let r of organizationContractList">
                            {{ r.report_name }}
                        </div>
                    </ng-template>
                </s25-ng-modal>
            </div>
            <div *ngIf="this.reservationContractBase.length > 0" class="c-margin-left--single">
                If Reservation Shell is selected, then
                <a class="c-textButton" (click)="this.docContractModal.open()">these document management reports</a>
                will be added to this event type.
                <s25-ng-modal #docContractModal [title]="'Reservation-Based Documents'" [size]="'xl'">
                    <ng-template #s25ModalBody>
                        <div *ngFor="let r of reservationContractList">
                            {{ r.report_name }}
                        </div>
                    </ng-template>
                </s25-ng-modal>
            </div>

            <label class="hierarchy--section-label" for="cat">Categories</label>
            <div id="cat">
                <ng-container>
                    <s25-ng-multiselect-search-criteria
                        [selectedItems]="catSelectedItems"
                        [modelBean]="this.modelBeanCat"
                        honorMatching="true"
                        type="eventCategories"
                    ></s25-ng-multiselect-search-criteria>
                </ng-container>
            </div>

            <label class="hierarchy--section-label" for="role">Contact Roles</label>
            <div id="role">
                <ng-container>
                    <s25-ng-multiselect-search-criteria
                        [selectedItems]="roleSelectedItems"
                        [modelBean]="this.modelBeanRole"
                        honorMatching="true"
                        type="eventRoles"
                    ></s25-ng-multiselect-search-criteria>
                </ng-container>
            </div>

            <label class="hierarchy--section-label" for="att">Custom Attributes</label>
            <div id="att">
                <s25-ng-multiselect-search-criteria
                    [selectedItems]="attSelectedItems"
                    [modelBean]="this.modelBeanAtt"
                    honorMatching="true"
                    type="eventCustomAttributes"
                ></s25-ng-multiselect-search-criteria>
            </div>

            <label class="hierarchy--section-label" for="req">Requirements (Publish to Calendar)</label>
            <div id="req">
                <s25-ng-multiselect-search-criteria
                    [selectedItems]="reqSelectedItems"
                    [modelBean]="this.modelBeanReq"
                    honorMatching="true"
                    type="eventRequirements"
                    customFilterValue="requirement_type=7"
                ></s25-ng-multiselect-search-criteria>
            </div>

            <label class="hierarchy--section-label" for="reqOther">Requirements (Other)</label>
            <div id="reqOther">
                <s25-ng-multiselect-search-criteria
                    [selectedItems]="reqOtherSelectedItems"
                    [modelBean]="this.modelBeanReqOther"
                    honorMatching="true"
                    type="eventRequirements"
                    customFilterValue="requirement_type=6"
                ></s25-ng-multiselect-search-criteria>
            </div>

            <label class="hierarchy--section-label" for="policy">Notification Policy</label>
            <div id="policy">
                <s25-ng-notification-policy
                    hasSaveButton="false"
                    [eventTypeNotificationPolicy]="getNotificationPolicy"
                    (valueChange)="onChangePolicy($event)"
                ></s25-ng-notification-policy>
            </div>

            <div *ngIf="!this.isCopy" class="c-displayBlock c-margin-top--single">
                <button
                    class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                    (click)="this.isCopy ? save(true) : save(false)"
                    [disabled]="!this.eventType.parent_id || !this.eventType.type_name"
                >
                    <!-- {{ this.loading ? "Saving...." : "Save" }} -->
                    {{ saveButtonText }}
                </button>
                <button
                    class="aw-button aw-button--danger--outline c-margin-top--single c-margin-right--quarter"
                    *ngIf="this.isEdit"
                    (click)="confirm('isDelete')"
                >
                    Delete
                </button>
                <button
                    class="aw-button aw-button--danger--outline c-margin-top--single c-margin-right--quarter"
                    *ngIf="this.isEdit && this.groupId === -1"
                    (click)="confirm('isForceDelete')"
                >
                    Force Delete
                </button>
                <button
                    class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                    *ngIf="this.isEdit"
                    (click)="deactive()"
                >
                    Deactivate
                </button>
                <button
                    class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                    *ngIf="this.isEdit"
                    (click)="copy()"
                >
                    Copy
                </button>
                <button
                    class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                    (click)="cancel()"
                >
                    Cancel
                </button>
            </div>

            <div *ngIf="this.isCopy">
                <button
                    class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                    (click)="save(true)"
                    [disabled]="!this.eventType.parent_id || !this.eventType.type_name || this.nameUsed"
                >
                    Save Copy
                </button>
                <button
                    class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                    (click)="cancelCopy()"
                >
                    Cancel
                </button>
            </div>

            <div *ngIf="this.deleteConfirmed">
                <s25-ng-event-type-delete
                    (modelValueChange)="this.onChangeDeleteConfirm($event)"
                ></s25-ng-event-type-delete>
            </div>
        </div>

        <!-- cancel button if new got back the list -->

        <div *ngIf="this.init && this.type === 'view'">
            <s25-ng-event-type-hierarchy resetType="true"></s25-ng-event-type-hierarchy>
        </div>
    `,
    styles: `
        s25-generic-dropdown {
            min-width: 250px;
            display: block;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EventTypeComponent implements OnInit {
    @Input() isEdit: any;
    @Input() eventTypeId: any;
    @Input() type: string = "E";
    @Input() itemTypeId: number = 1;
    @Input() isCopy?: boolean = false;
    valid: boolean;
    msg: any;
    eventType: EventTypeFormI = undefined;
    vCal = "U";
    vCalOptions = [
        { val: "U", txt: "Unlocked Transactional" },
        { val: "L", txt: "Locked Transactional" },
        { val: "N", txt: "Naturalize" },
    ];
    modelBeanRole: any = { showResult: true, showMatching: false };
    modelBeanCat: any = { showResult: true, showMatching: false };
    modelBeanAtt: any = { showResult: true, showMatching: false };
    modelBeanReq: any = { showResult: true, showMatching: false };
    modelBeanReqOther: any = { showResult: true, showMatching: false };
    reports: any;
    reportsDropdownList: any;
    checkedAllReports: boolean = false;
    init = false;
    reportsInit = false;
    loading = false;
    allTypes: any;
    cabinets: any;
    parentId = 0;
    folders: any;
    chosenCabinet: any;
    notificationPolicy: any = {};
    getNotificationPolicy: any = {};
    roleSelectedItems = [
        { itemId: -2, itemName: "Scheduler", isPermanent: true },
        { itemId: -1, itemName: "Requestor", isPermanent: true },
    ];
    catSelectedItems: any = [];
    attSelectedItems: any = [];
    reqSelectedItems: any = [];
    reqOtherSelectedItems: any = [];
    roleSelectedItemsInit: any = [];
    catSelectedItemsInit: any = [];
    attSelectedItemsInit: any = [];
    reqSelectedItemsInit: any = [];
    reqOtherSelectedItemsInit: any = [];
    approvalTypes = [
        { itemName: "No Notification Policy", itemId: 0 },
        { itemName: "By at least one", itemId: 1 },
        { itemName: "By all", itemId: 2 },
    ];
    notifyTypes = [
        { itemName: "Notify only", itemId: 1 },
        { itemName: "Approval required", itemId: 2 },
    ];
    chosenFolder: any;
    groupId: number;
    deleteConfirmed: boolean = false;
    isForceDelete: boolean = false;
    saveButtonText: string = "Save";
    folderTypeMsg: string = "";
    nameUsed: boolean = false;

    staticItems = [
        {
            dropDownOrigName: "No Default",
            isSelected: true,
            itemId: 0,
            itemName: "No Default",
            noAnchorClick: true,
            txt: "No Default",
            val: "",
        },
    ];
    chosenConfirmation: any = [];
    chosenInvoice: any = [];
    selectedReports: any = [];
    modelBeanReports: any = { showResult: true, showMatching: false, uuid: "selectedReports-uuid" };

    @ViewChild(S25NotificationPolicyComponent) np: S25NotificationPolicyComponent;
    @ViewChild("docContractModal") docContractModal: S25ModalComponent;

    eventContractBase: any = []; // report_id = -198 event contract shell report
    organizationContractBase: any = []; // report_id = -217 organization contract shell report
    reservationContractBase: any = []; // report_id = -210 reservation contract shell report
    eventContractList: any = [];
    organizationContractList: any = [];
    reservationContractList: any = [];

    /* object_types are:
     event document (10), organization document (13), reservation document (12)
     event (1), org (2), contact (3), location (4), resource (6), pricing set (11)
    */

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
        private location: Location,
    ) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.zone.run(() => {
            if (this.itemTypeId === 1) {
                Promise.all([
                    this.getEventTypeReports(),
                    this.getGroupId(),
                    this.getAllEventTypes(),
                    !this.isEdit ? this.postEventType() : this.getEventTypeById(this.eventTypeId),
                    this.modelBeanRole.dataPromise && this.modelBeanRole.dataPromise,
                ]).then((resp) => {
                    this.setReportFields(); // prepopuation event form report fields after gathering all info
                    this.isCopy ? this.copy() : "";
                    this.modelBeanReports.onChange = () => {
                        this.onChangeReportsValue(); // check any base shell report
                    };
                    this.init = true;
                    this.detectChanges();
                });
            }
            this.detectChanges();
        });
    }

    setFolderTypeMsg() {
        this.chosenFolder === "" && this.folders !== ""
            ? (this.folderTypeMsg = "Folder type has not been selected.")
            : (this.folderTypeMsg = "");
    }

    goToHierarchy() {
        let url = HierarchyUtil.getCompUrl("hierarchy");
        window.location.assign(url);
    }

    getAllEventTypes() {
        return EventService.getAllEventType().then((data) => {
            this.allTypes = data;
            this.cabinets = HierarchyUtil.decorateItems(this.allTypes, "C", "node_type", 0);
            this.folders = HierarchyUtil.decorateItems(this.allTypes, "F", "node_name");
        });
    }

    postEventType() {
        this.initFunc(); // reset some vars
        return EventService.postEventType().then((data) => {
            this.eventType = data as any;
            this.eventType.inheritance_rules = HierarchyUtil.inheritanceRules(); // set inheritanceRules default values
            this.detectChanges();
        });
    }

    getEventTypeById(id: any) {
        return EventService.getEventTypeById(id).then((data) => {
            this.eventType = data;
            this.eventType.contact_role
                ? (this.roleSelectedItems = HierarchyUtil.decorateSelectedItems(
                      this.eventType.contact_role,
                      "role_id",
                      "role_name",
                  ))
                : "";
            this.eventType.categories
                ? (this.catSelectedItems = HierarchyUtil.decorateSelectedItems(
                      this.eventType.categories,
                      "category_id",
                      "category_name",
                  ))
                : "";
            this.eventType.custom_attributes
                ? (this.attSelectedItems = HierarchyUtil.decorateSelectedItems(
                      this.eventType.custom_attributes,
                      "attribute_id",
                      "attribute_name",
                  ))
                : "";
            let getVCal: any = S25Util.array.getByProp(this.eventType.inheritance_rules, "inherit_item", "Vcal");
            this.vCal = getVCal.vcal;

            if (this.eventType.requirements) {
                let reqCal: any;
                let reqOther: any;
                let getAllReq: any = this.eventType.requirements;
                if (S25Util.array.isArray(this.eventType.requirements)) {
                    reqCal = this.eventType.requirements.filter((i: any) => i.requirement_type === 7);
                    reqOther = this.eventType.requirements.filter((i: any) => i.requirement_type === 6);
                } else {
                    getAllReq.requirement_type === 7
                        ? (reqCal = this.eventType.requirements)
                        : (reqOther = this.eventType.requirements);
                }
                if (reqCal) {
                    this.reqSelectedItems = HierarchyUtil.decorateSelectedItems(
                        reqCal,
                        "requirement_id",
                        "requirement",
                    );
                }
                if (reqOther) {
                    this.reqOtherSelectedItems = HierarchyUtil.decorateSelectedItems(
                        reqOther,
                        "requirement_id",
                        "requirement",
                    );
                }
            }

            if (this.eventType.notifications) {
                let approvalTypeModel = S25Util.array.getByProp(
                    this.approvalTypes,
                    "itemId",
                    this.eventType.notifications.approval_from,
                );
                this.getNotificationPolicy.approvalType = S25Util.deepCopy(approvalTypeModel);
                let duration = S25Util.ISODurationToObj(this.eventType.notifications.respond_within);
                this.getNotificationPolicy.days = duration.days;
                this.getNotificationPolicy.hours = duration.hours;
                this.getNotificationPolicy.minutes = duration.minutes;
                let contacts: any = [];
                if (
                    this.eventType.notifications.contacts &&
                    S25Util.array.isArray(this.eventType.notifications.contacts)
                ) {
                    jSith.forEach(this.eventType.notifications.contacts, (key, c) => {
                        let notifyTypeId = S25Util.propertyGet(c, "notify_type");
                        let notifyTypeModel = null;
                        if (notifyTypeId) {
                            notifyTypeModel = S25Util.array.getByProp(this.notifyTypes, "itemId", notifyTypeId);
                        }
                        contacts.push({
                            itemId: c.contact_id,
                            itemName: c.contact_name,
                            notifyType: S25Util.deepCopy(notifyTypeModel),
                        });
                    });
                } else {
                    if (this.eventType.notifications.contacts) {
                        let notifyTypeId = S25Util.propertyGet(this.eventType.notifications.contacts, "notify_type");
                        let notifyTypeModel = null;
                        if (notifyTypeId) {
                            notifyTypeModel = S25Util.array.getByProp(this.notifyTypes, "itemId", notifyTypeId);
                        }

                        let newContact: any = [];
                        newContact.push(this.eventType.notifications.contacts);

                        contacts.push({
                            itemId: newContact[0].contact_id,
                            itemName: newContact[0].contact_name,
                            notifyType: S25Util.deepCopy(notifyTypeModel),
                        });
                    }
                }
                this.getNotificationPolicy.contacts = contacts;
            } //end notifications

            this.roleSelectedItemsInit = S25Util.deepCopy(this.roleSelectedItems);
            this.catSelectedItemsInit = S25Util.deepCopy(this.catSelectedItems);
            this.attSelectedItemsInit = S25Util.deepCopy(this.attSelectedItems);
            this.reqSelectedItemsInit = S25Util.deepCopy(this.reqSelectedItems);
            this.reqOtherSelectedItemsInit = S25Util.deepCopy(this.reqOtherSelectedItems);

            this.detectChanges();
        });
    }

    onChangeName(e: any) {
        this.eventType.type_name = e.target.value;
        this.detectChanges();
    }

    onChangeVCal(e: any) {
        this.eventType.inheritance_rules = this.eventType.inheritance_rules.map((r: any) => {
            if (r.inherit_item === "Vcal") {
                r.inherit_rule = e.target.value;
                r.vcal = e.target.value;
            }
            return r;
        });
        this.detectChanges();
    }

    onChangeReportDropdown(e: any) {
        //if new selected confirmation/invoice report not in multiselected,  add to the list
        let find = this.selectedReports.filter((item: any) => item.itemId === e.itemId);
        if (find.length === 0 && e.itemId !== 0) this.selectedReports.push(e); // no default itemId = 0
        //reset confirmation and invoice reports in multiselected not allow to removed
        this.selectedReports.map((item: any) => {
            item.itemId === this.chosenConfirmation.itemId || item.itemId === this.chosenInvoice.itemId
                ? (item.isPermanent = true)
                : (item.isPermanent = false); // if report us confirmation/invoice, not allow to removed from multi selected
            return item;
        });
        MultiselectResultsApi.refresh(this.elementRef.nativeElement, "selectedReports-uuid");
    }

    onChangePolicy(e: any) {
        this.notificationPolicy = e;
        this.detectChanges();
    }

    onChangeCabinet(e: any) {
        this.chosenFolder = "";
        //this.eventType.parent_id = null;
        this.eventType.parent_id = e.itemId; //ANG4191
        this.folders = this.allTypes.filter((i: any) => i.parent_id === e.itemId && i.node_type === "F");
        this.folders = this.folders.map((obj: any) => {
            return { itemName: obj.type_name, itemId: obj.type_id };
        });
        let el: any = "s25-ng-hierarch-dropdown";
        DropdownApi.refresh(el, this.folders);
        this.detectChanges();
    }

    onChangeFolder(e: any) {
        this.eventType.parent_id = e.itemId;
        this.folderTypeMsg = "";
        this.detectChanges();
    }

    view() {
        this.location.back();
    }

    save(isCopy?: boolean) {
        // isCopy = ture from Copy function
        if (this.validate()) {
            this.loading = true;
            isCopy ? (this.saveButtonText = "Copying...") : (this.saveButtonText = "Saving...");
            this.cd.detectChanges();
            this.eventType.node_type = "E";
            this.eventType.defn_state = 1;
            if (this.isEdit) {
                this.eventType.status = "mod";
            } else {
                this.eventType.status = "new";
                if (!isCopy) {
                    delete this.eventType.notifications;
                    delete this.eventType.class_name;
                    delete this.eventType.favorite;
                    delete this.eventType.last_mod_dt;
                    delete this.eventType.last_mod_user;
                    delete this.eventType.node_type_name;
                    delete this.eventType.routing_rules;
                    delete this.eventType.sort_order;
                    delete this.eventType.routing_rules;
                    delete this.eventType.tag_value;
                }
            }

            let reports = [];
            let use = 0;
            //set selelected report
            for (let j = 0; j < this.selectedReports.length; j++) {
                let status = "mod";
                !S25Util.array.isIn(this.eventType.reports, "report_id", parseInt(this.selectedReports[j].itemId))
                    ? (status = "new")
                    : "";
                this.selectedReports[j].itemId === this.chosenConfirmation.report_id
                    ? (use = 1)
                    : this.selectedReports[j].itemId === this.chosenInvoice.report_id
                      ? (use = 2)
                      : (use = 0);
                reports.push({
                    report_engine: this.selectedReports[j].report_engine,
                    report_id: this.selectedReports[j].report_id,
                    report_name: this.selectedReports[j].report_name,
                    report_use: use,
                    status: status,
                });
            }
            // set removed report
            if (this.modelBeanReports.removedItems.length > 0) {
                for (let r = 0; r < this.modelBeanReports.removedItems.length; r++) {
                    if (
                        S25Util.array.isIn(
                            this.eventType.reports,
                            "report_id",
                            parseInt(this.modelBeanReports.removedItems[r].itemId),
                        )
                    ) {
                        if (!isCopy) {
                            reports.push({
                                report_engine: this.modelBeanReports.removedItems[r].report_engine,
                                report_id: this.modelBeanReports.removedItems[r].report_id,
                                report_name: this.modelBeanReports.removedItems[r].report_name,
                                report_use: use,
                                status: "del",
                            });
                        }
                    }
                }
            }
            // end report

            !S25Util.array.isEmpty(reports) ? (this.eventType.reports = reports) : "";
            S25Util.array.isEmpty(reports) ? delete this.eventType.reports : "";

            if (this.notificationPolicy && this.notificationPolicy.approvalType) {
                if (this.notificationPolicy.approvalType.itemId === 0) {
                    // ANG-3906 changed TO No Notification Policy
                    if (this.eventType.notifications) this.eventType.notifications.status = "del";
                } else {
                    let status = "est";
                    this.eventType.notifications ? (status = "mod") : (status = "new");
                    this.eventType.notifications = {};
                    this.eventType.notifications.approval_from = this.notificationPolicy.approvalType.itemId;
                    this.eventType.notifications.status = status;
                    let contacts: any = [];
                    let tempContact = this.notificationPolicy.contacts.filter((item: any) => item.status != "del");
                    jSith.forEach(tempContact, (key, c) => {
                        contacts.push({
                            contact_id: c.itemId,
                            contact_name: c.itemName,
                            notify_type: c.notifyType.itemId,
                            status: c.status,
                        });
                    });
                    if (contacts.length > 0) {
                        this.eventType.notifications.contacts = contacts;
                    }
                    if (
                        this.notificationPolicy.approvalType.itemId === 1 ||
                        this.notificationPolicy.approvalType.itemId === 2
                    ) {
                        let respondWithIn = {
                            days: this.notificationPolicy.days || 0,
                            hours: this.notificationPolicy.hours || 0,
                            minutes: this.notificationPolicy.minutes || 0,
                        };
                        this.eventType.notifications.respond_within = S25Util.daysHoursMinutesToDuration(respondWithIn);
                    }
                }
            } // end policy

            let req: any = [];
            let otherReq: any = [];
            if (this.modelBeanReq.selectedItems) {
                req = HierarchyUtil.decorateItemsToSave(
                    this.modelBeanReq.selectedItems,
                    this.reqSelectedItemsInit,
                    this.modelBeanReq.removedItems,
                    "requirement_id",
                    "requirement",
                    this.isEdit,
                    "requirement_type",
                    6,
                );
            }
            if (this.modelBeanReqOther.selectedItems) {
                otherReq = HierarchyUtil.decorateItemsToSave(
                    this.modelBeanReqOther.selectedItems,
                    this.reqOtherSelectedItemsInit,
                    this.modelBeanReqOther.removedItems,
                    "requirement_id",
                    "requirement",
                    this.isEdit,
                    "requirement_type",
                    7,
                );
            }
            this.eventType.requirements = req.concat(otherReq);
            S25Util.array.isEmpty(this.eventType.requirements) ? delete this.eventType.requirements : "";

            if (this.modelBeanCat.selectedItems) {
                this.eventType.categories = HierarchyUtil.decorateItemsToSave(
                    this.modelBeanCat.selectedItems,
                    this.catSelectedItemsInit,
                    this.modelBeanCat.removedItems,
                    "category_id",
                    "category_name",
                    this.isEdit,
                );
            }
            S25Util.array.isEmpty(this.eventType.categories) ? delete this.eventType.categories : "";

            if (this.modelBeanRole.selectedItems) {
                this.eventType.contact_role = HierarchyUtil.decorateItemsToSave(
                    this.modelBeanRole.selectedItems,
                    this.roleSelectedItemsInit,
                    this.modelBeanRole.removedItems,
                    "role_id",
                    "role_name",
                    this.isEdit,
                );
            }
            S25Util.array.isEmpty(this.eventType.contact_role) ? delete this.eventType.contact_role : "";

            if (this.modelBeanAtt.selectedItems) {
                this.eventType.custom_attributes = HierarchyUtil.decorateItemsToSave(
                    this.modelBeanAtt.selectedItems,
                    this.attSelectedItemsInit,
                    this.modelBeanAtt.removedItems,
                    "attribute_id",
                    "attribute_name",
                    this.isEdit,
                );
            }
            S25Util.array.isEmpty(this.eventType.custom_attributes) ? delete this.eventType.custom_attributes : "";

            LockService.lock(1005, 1101);

            let saveMsg = "ET_I_CREATED";
            this.isEdit ? (saveMsg = "ET_I_SAVED") : "";

            EventService.putEventType(this.eventType.type_id, this.eventType, saveMsg).then((resp) => {
                if (resp && resp.success) {
                    this.loading = false;
                    this.isCopy = false;
                    this.saveButtonText = "Save";
                    LockService.delete(1101, 1005);
                    if (!this.isEdit) {
                        this.isEdit = true;
                        this.eventTypeId = this.eventType.type_id;
                        //this.ngOnInit(); // new event type just created, Init again to update some vars
                    }
                    this.type = "view";
                    if (this.eventType.node_type && this.eventType.type_id) {
                        //return to hierarchy on save with new folder/cabinet highlighted
                        let hierarchyUrl =
                            HierarchyUtil.getCompUrl("hierarchy") +
                            "/" +
                            this.itemTypeId +
                            "/view/" +
                            this.eventType.node_type +
                            "/" +
                            this.eventType.type_id;
                        window.location.assign(hierarchyUrl);
                    }
                    console.log("Update done");
                    //}
                    //window.location.reload();
                    this.detectChanges();
                }
            });
        } //endif valid
    }

    cancel() {
        let id = this.eventType.type_id;
        this.isCopy = false;
        !this.isEdit ? (id = 1) : "";
        let hierarchyUrl =
            HierarchyUtil.getCompUrl("hierarchy") + "/" + this.itemTypeId + "/view/E/" + this.eventType.type_id;
        window.location.assign(hierarchyUrl);
        /*
		this.init = false;  // set back to false, tried MultiselectApi.refresh but did not make changes
		this.initFunc();
		this.ngOnInit();
		this.detectChanges();
          setTimeout(() => {
               this.location.back();
          }, 120);
          */
    }

    cancelCopy() {
        let id = this.eventType.type_id;
        this.isCopy = false;
        !this.isEdit ? (id = 1) : "";
        history.back();
    }

    delete() {
        let payload = {
            type_id: this.eventType.type_id,
            status: "del",
        };

        return AlertService.confirm("Are you sure you want to delete this event type?").then((answer: boolean) => {
            if (answer) {
                LockService.lock(1005, 1101);
                return EventService.putEventType(this.eventType.type_id, payload, "ET_I_DELETED").then((resp) => {
                    if (resp && resp.success) {
                        LockService.delete(1101, 1005);
                        alert("Event type has been deleted.");
                        let hierarchyUrl =
                            HierarchyUtil.getCompUrl("hierarchy") +
                            "/" +
                            this.itemTypeId +
                            "/view/" +
                            this.eventType.node_type +
                            "/0";
                        window.location.assign(hierarchyUrl);
                        window.location.reload();
                    }
                });
            }
        });
    }

    forceDelete() {
        return AlertService.confirm(
            "Are you sure that you want to delete this master definition and remove all references to it?",
        ).then((answer) => {
            if (answer) {
                LockService.lock(1005, 1101);
                return MasterDefinitionService.forceDelete("event_type", this.eventType.type_id).then((resp: any) => {
                    LockService.delete(1101, 1005);
                    alert("Event type has been deleted.");
                    let hierarchyUrl =
                        HierarchyUtil.getCompUrl("hierarchy") +
                        "/" +
                        this.itemTypeId +
                        "/view/" +
                        this.eventType.node_type +
                        "/0";
                    window.location.assign(hierarchyUrl);
                    window.location.reload();
                });
            }
        });
    }

    confirm(e: any) {
        this.deleteConfirmed = true;
        e === "isForceDelete" ? (this.isForceDelete = true) : (this.isForceDelete = false);
    }

    onChangeDeleteConfirm(e: any) {
        if (e === "confirmed") {
            this.isForceDelete ? this.forceDelete() : this.delete();
        } else {
            this.deleteConfirmed = false;
        }
    }

    copy() {
        //ANG-3822 make sure no event type name NOT in use
        let find = this.allTypes.find((a: any) => {
            return a && a.type_name === "Copy of " + this.eventType.type_name;
        });

        if (find) {
            this.nameUsed = true;
            alert(
                "Copy of " +
                    this.eventType.type_name +
                    " has already been used for another type.  Please specify a unique name for the type.",
            );
        } else {
            this.nameUsed = false;
            return EventService.postEventType().then((data) => {
                this.eventType.type_id = data.type_id;
                this.eventType.type_name = "Copy of " + this.eventType.type_name;
                this.isEdit = false;
                this.isCopy = true;
                this.saveButtonText = "Save Copy";
                window.scrollTo(0, 0); // per Shannon make it consistent as List View copy, users can easily tell that they can make changes to the copy before saving
                this.detectChanges();
            });
        }
    }

    deactive() {
        let payload = {
            type_id: this.eventType.type_id,
            type_name: this.eventType.type_name,
            node_type: this.eventType.node_type,
            defn_state: 0,
            status: "mod",
        };

        return AlertService.confirm("Are you sure you want to deactive this event type?").then((answer: boolean) => {
            if (answer) {
                LockService.lock(1005, 1101);
                return EventService.putEventType(this.eventType.type_id, payload, "ET_I_SAVED").then((resp) => {
                    if (resp && resp.success) {
                        this.eventType.defn_state = 0;
                        LockService.delete(this.eventType.type_id, 1005);
                        alert("Event type has been deactived.");
                    }
                });
            }
        });
    }

    getEventTypeReports() {
        return EventService.getEventTypeReports().then((data: any) => {
            this.reports = data;
            this.reportsInit = true;
        });
    }

    async setReportFields() {
        this.eventType.reports = S25Util.array.forceArray(this.eventType.reports);
        this.eventContractBase = this.eventType.reports.filter((item: any) => item?.report_id === -198);
        this.organizationContractBase = this.eventType.reports.filter((item: any) => item?.report_id === -217);
        this.reservationContractBase = this.eventType.reports.filter((item: any) => item?.report_id === -210);
        // mapping itemId and itemName for generic-dropdown
        this.selectedReports = this.eventType.reports.map((report: any) => {
            report.itemId = report.report_id;
            report.report_engine === "DM"
                ? (report.itemName = report.object_type_name + " Document: " + report.report_name)
                : (report.itemName = report.report_name);
            if (report.report_use === 1 || report.report_use === 2) report.isPermanent = true; // if report is confirmation/invoice, not allow to revmoved from multi selected
            return report;
        });
        let chosenConfirmation = this.eventType.reports.filter((item: any) => item?.report_use === 1);
        let chosenInvoice = this.eventType.reports.filter((item: any) => item?.report_use === 2);
        if (chosenConfirmation.length !== 0) this.chosenConfirmation = chosenConfirmation[0];
        if (chosenInvoice.length !== 0) this.chosenInvoice = chosenInvoice[0];

        this.reports = await ReportService.normalizeReport(this.reports, true);
        //report dropdown list. ANG-4917 include DM reports but no reservation reports (object type = 12_) ,  also no shell base reports
        this.reportsDropdownList = this.reports.filter(
            (item: any) =>
                [1, 10, 13].indexOf(item.object_type) > -1 &&
                item.report_id !== -217 &&
                item.report_id !== -210 &&
                item.report_id !== -198,
        );

        this.eventContractList = this.reports.filter((item: any) => item.object_type === 10 && item.report_id !== -198);
        this.organizationContractList = this.reports.filter(
            (item: any) => item.object_type === 13 && item.report_id !== -217,
        );
        this.reservationContractList = this.reports.filter(
            (item: any) => item.object_type === 12 && item.report_id !== -210,
        );

        this.cd.detectChanges();
    }

    onChangeReportsValue() {
        this.eventContractBase = this.modelBeanReports.selectedItems.filter((item: any) => item?.report_id === -198);
        this.organizationContractBase = this.modelBeanReports.selectedItems.filter(
            (item: any) => item?.report_id === -217,
        );
        this.reservationContractBase = this.modelBeanReports.selectedItems.filter(
            (item: any) => item?.report_id === -210,
        );
        this.detectChanges();
    }

    validate() {
        this.msg = "";
        if (!this.eventType.parent_id && !this.isEdit) {
            this.msg = "Please select a folder";
            alert(this.msg);
            this.valid = false;
            return false;
        }

        if (!this.eventType.type_name) {
            this.msg = "Event type name is required";
            alert(this.msg);
            this.valid = false;
            return false;
        }

        if (this.notificationPolicy.approvalType && this.notificationPolicy.approvalType.itemId !== 0) {
            return this.np.validate();
        } else {
            return true;
        }
    }

    initFunc() {
        this.notificationPolicy = {};
        this.getNotificationPolicy = {};
        this.catSelectedItems = [];
        this.attSelectedItems = [];
        this.reqSelectedItems = [];
        this.reqOtherSelectedItems = [];
        this.roleSelectedItemsInit = [];
        this.catSelectedItemsInit = [];
        this.attSelectedItemsInit = [];
        this.reqSelectedItemsInit = [];
        this.reqOtherSelectedItemsInit = [];
    }

    getParentId(id: any) {
        this.parentId = id;
        this.folders = this.allTypes.filter((i: any) => i.parent_id === id && i.node_type === "F");
    }

    ngOnDestory() {
        this.eventTypeId = {};
        HierarchyApi.refresh(this.elementRef.nativeElement);
    }

    getGroupId() {
        return UserprefService.getGroupId().then((resp: any) => {
            resp ? (this.groupId = resp) : "";
            this.detectChanges();
        });
    }

    detectChanges() {
        this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
    }
}
